<script setup>
import AppWrapper from "@/Layouts/AppWrapper.vue";
import AttributionsButtonIcon from "@/Pages/Attributions/Components/AttributionsButtonIcon.vue";
import ObjectInformationButtonIcon from "@/Pages/ObjectInformation/Components/ObjectInformationButtonIcon.vue";
import ApplicationNaviNoaLogo from "@/Components/ApplicationNaviNoaLogo.vue";
import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";
import {router} from "@inertiajs/vue3";
import {onUnmounted, useSlots} from "vue";
import SettingsButtonIcon from "@/Pages/Settings/Components/SettingsButtonIcon.vue";
import ApplicationClientLogo from "@/Components/ApplicationClientLogo.vue";


const {navStore} = stores();
const {activePage} = storeToRefs(navStore);

const props = defineProps({
    logoNav: {
        type: Boolean,
        default: false
    }
})

onUnmounted(
    router.on('start', (event) => {
        navStore.setActivePage(null);
    })
)

function setActivePage(page) {
    navStore.setActivePage(page);
}


const slots = useSlots()
const hasSlot = (name) => {
    return !!slots[name];
}

</script>


<template>
    <AppWrapper>


        <div
            :class="[hasSlot('header')? 'mt-36' : 'mt-20']"
            class="bg-white mb-14">

            <!-- Primary Navigation Menu -->
            <nav class="fixed top-0 bg-white border-b border-gray-100 mb-10 z-50 w-full shadow-md">

                <div class="max-w-7xl mx-auto px-6 sm:px-6 lg:px-8 ">
                    <div class="flex justify-between h-20">


                        <div class="flex">
                            <!-- Logo -->
                            <div class="shrink-0 flex items-center">
                                 <span @click="router.get(route('content.index'))">
                                      <ApplicationNaviNoaLogo class="h-11"/>
                                </span>

                            </div>
                        </div>

                        <div class="flex" v-if="logoNav">
                            <!-- Logo -->
                            <div class="shrink-0 flex items-center">
                                <ApplicationClientLogo class="h-11"/>
                            </div>
                        </div>

                        <div
                            v-if="!logoNav"
                            class="flex items-center space-x-6">
<!--                            <AttributionsButtonIcon-->
<!--                                :class="activePage === 'attributions' ? 'text-brainkitPrimary' : ''"-->
<!--                                @click="setActivePage('attributions')"/>-->


                            <ObjectInformationButtonIcon
                                :class="activePage === 'object-information' ? 'text-brainkitPrimary' : ''"
                                @click="setActivePage('object-information')"/>

                            <SettingsButtonIcon
                                :class="activePage === 'settings' ? 'text-brainkitPrimary' : ''"
                                @click="setActivePage('settings')"/>
                        </div>

                    </div>
                </div>
            </nav>

            <div
                v-if="hasSlot('header')"
                class="bg-slate-100 absolute top-16 pt-8 pb-4 right-0 left-0 rounded-bl rounded-br shadow">
                <slot name="header"/>
            </div>

            <!-- Page Content -->
            <main class="max-w-7xl mx-auto sm:px-0 lg:px-8">
                <div class="bg-white shadow-sm sm:rounded-lg py-4 px-4 md:p-8 md:my-12">
                    <slot/>
                </div>
            </main>
        </div>

    </AppWrapper>
</template>
